import { FCWithChildren } from '../../../../types/FCWithChildren';
import { Heading, HeadingSize } from '../../text/Heading';
import { Modal } from '../Modal';
import Button, { ButtonSize, ButtonType } from '../../form-control/Button';
import PlusIcon from '../../icon/PlusIcon';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

type Props = {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
  onTertiaryButtonClick?: () => void;
  confirmDisabled?: boolean;
  confirmDisabledTooltip?: string;
  confirmLoading?: boolean;
  confirmButtonTitle?: string | null;
  cancelButtonTitle?: string | null;
  tertiaryButtonTitle?: string | null;
  tertiaryButtonIcon?: ReactElement | null;
  alt?: boolean;
  uncloseable?: boolean;
  bare?: boolean;
};

const StandardModal: FCWithChildren<Props> = (props) => {
  const { t } = useTranslation('common');
  const {
    title,
    subTitle,
    onCancelClick,
    onConfirmClick,
    onTertiaryButtonClick,
    confirmDisabled,
    confirmDisabledTooltip,
    confirmLoading,
    cancelButtonTitle = t('modals.cancel'),
    confirmButtonTitle = t('modals.save'),
    tertiaryButtonTitle,
    tertiaryButtonIcon = <PlusIcon className="h-3 w-3" />,
    alt,
    uncloseable,
    children,
    bare,
  } = props;

  return (
    <Modal data-cy="modal" uncloseable={uncloseable}>
      <Modal.Header align="left" underline={(!!title || !!subTitle) && !bare}>
        {title && (
          <Heading size={HeadingSize.H5} className="truncate font-medium">
            {title}
          </Heading>
        )}
        {subTitle && (
          <>
            {/* Tooltip seperator */}
            <span className="hidden"> - </span>

            <div className="text-dpm-16 text-color-on-first-primary-1 py-1 font-normal">{subTitle}</div>
          </>
        )}
      </Modal.Header>
      <Modal.Body padding={bare ? 'p-0' : 'px-6 py-4'}>{children}</Modal.Body>
      <Modal.Footer
        justification="justify-between"
        padding={!!onTertiaryButtonClick || !!onCancelClick || !!onConfirmClick ? /* default */ undefined : 'p-0'}
        topline={!!onTertiaryButtonClick || !!onCancelClick || !!onConfirmClick}
      >
        <div>
          {onTertiaryButtonClick && tertiaryButtonTitle && (
            <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={onTertiaryButtonClick}>
              {tertiaryButtonIcon && <Button.Slot name="Icon">{tertiaryButtonIcon}</Button.Slot>}
              {tertiaryButtonTitle}
            </Button>
          )}
        </div>
        <div className={`flex gap-4 ${alt && 'flex-row-reverse'}`}>
          {onCancelClick && cancelButtonTitle && (
            <Button
              type={alt ? ButtonType.PRIMARY : ButtonType.SECONDARY}
              size={ButtonSize.S}
              onClick={onCancelClick}
              data-cy="modal-cancel"
              disabled={confirmLoading}
            >
              {cancelButtonTitle}
            </Button>
          )}
          {onConfirmClick && confirmButtonTitle && (
            <Button
              type={alt ? ButtonType.SECONDARY : ButtonType.PRIMARY}
              size={ButtonSize.S}
              disabled={confirmDisabled}
              onClick={onConfirmClick}
              loading={confirmLoading}
              data-cy="modal-done"
              title={confirmDisabled ? confirmDisabledTooltip : undefined}
            >
              {confirmButtonTitle}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StandardModal;
