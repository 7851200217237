import { createContext, useContext } from 'react';

type ModalContextType = {
  open?: boolean;
  modalWidth?: string;
  modalMaxWidth?: string;
  showCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
  onClose?: () => void;
  className?: string;
  priorityOverride?: number;
};

export const ModalContext = createContext<ModalContextType>({ open: false });
export const useModal = (): ModalContextType => useContext(ModalContext);
